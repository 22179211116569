import React from "react";
import style from "./Section1Card.module.css";

const Section1Card = () => {
  const handleRedirect = () => {
    window.location.href = 'https://app.talent14.com/';
  };
  return (
    <div className={`${style.section1Card}`}>
      <p className={`${style.title1} m-0 p-0`}>Hem Oyna</p>
      <p className={`${style.title2} m-0 p-0`}>Hem Kodla</p>
      <p className={`${style.desc}`}>
        Platformda Oyun Oynarken ve Video İzlerken Kodlama ve Yazılım
        Öğrenebilir aynı zamanda Bilişim Sertifikası kazanabilirsin!
      </p>
      <div className={`${style.btnDiv}`}>
      <button className="" onClick={handleRedirect}>Kayıt Ol</button>
      </div>
    </div>
  );
};

export default Section1Card;
