import React from "react";
import style from "./Section3.module.css";
import web from "../../../../assets/section3web.png";
import background from "../../../../assets/section3background.png";

const Section3 = () => {
  return (
    <div className={`${style.section3} my-5`}>
      <div className={`${style.leftBox}`}>
        <div className={`${style.textContainer}`}>
          <div className={`${style.titleContainer}`}>
            <p className={`${style.title1} m-0`}>TALENT14</p>
            <p className={`${style.title2} m-0`}>
              Uygulamalı ve Etkileşimli Eğitim Platformu
            </p>
            <p className={`${style.title3} m-0`}>
              <span className={`${style.span1}`}> Bilişim School</span>
              <span className={`${style.span2}`}> Projesidir.</span>
            </p>
          </div>
          <p className={`${style.descContainer}`}>
            TALENT FOR TEEN, kodlama öğrenmenin yanı sıra, eğlenceli ve kolay
            bir şekilde öğrendiklerini uygulayarak kalıcı hale getirebildiğiniz
            eğitim platformudur.
          </p>
        </div>
        <div className={`${style.imgage1}`}>
          <img src={web} alt="web" />
        </div>
      <div className={`${style.imgage2}`}>
        <img src={background} alt="web" />
      </div>
      </div>
    </div>
  );
};

export default Section3;
