import React, { useState } from "react";
import style from "./Section1.module.css";
import Header from "../../../../components/Header";
import talent14Logo2 from "../../../../assets/TALENT14_LOGO_2.png";
import hamburgerIcon from "../../../../assets/hamburgerMenu2.png";
import circle1 from "../../../../assets/circle1.png";
import circle2 from "../../../../assets/circle2.png";
import circle3 from "../../../../assets/circle2.png";
import ToggleSwitch from "../toggleSwitch/ToggleSwitch";
import { FaPlus } from "react-icons/fa6";
import { free, kurumsal, premium } from "../../../../constants/pricing";

const Section1 = () => {
  const [isAnnual, setIsAnnual] = useState(true);
  const handleToggle = (checked) => {
    setIsAnnual(!isAnnual);
  };
  return (
    <div className={`${style.section1} m-0`}>
      <div className={`${style.section1Header}`}>
        <Header logo={talent14Logo2} hamburgerIcon={hamburgerIcon} />
      </div>
      <img
        src={circle1}
        className={`${style.circle1}`}
        alt="Ön Plan Görüntüsü"
      />
      <img
        src={circle2}
        className={`${style.circle2}`}
        alt="Ön Plan Görüntüsü"
      />
      <img
        src={circle3}
        className={`${style.circle3}`}
        alt="Ön Plan Görüntüsü"
      />

      <div className={`${style.center}`}>
        <p className={`${style.title} m-0`}>Fiyatlandırma</p>
        <p className={`${style.description} m-0`}>
          Fiyatlarımız, işinizi daha verimli ve karlı hale getirmenize yardımcı
          olacak çeşitli paket ve esnek seçenekleri içerir.
        </p>
      </div>
      <div className={style.cardContainer}>
        <div className={style.card1}>
          <h3 className={`${style.cardTitle} m-0`}>Ücretsiz</h3>
          <div className={`${style.line1}`}></div>
          <h3 className={`${style.cardTitle2} m-0`}>Hemen Deneyin</h3>
          <div className={`${style.line2}`}></div>
          {free.map((item, i) => (
            <p key={i} className={`${style.cardDescription} m-0`}>
              {item}
            </p>
          ))}
          <button className={`${style.btn}`}
            onClick={() =>
              (window.location.href = "https://app.talent14.com/login")
            }
          >
            <span>Öğrenmeye Başla</span>
          </button>
        </div>

        <div className={`${style.card2}`}>
          <div className={`${style.titleContainer}`}>
            <h3 className={`${style.cardTitle} m-0`}>Premium</h3>
            <div className={`${style.titleButton}`}>
              <span>Popüler</span>
            </div>
          </div>

          <div className={`${style.line1}`}></div>
          <div className={`${style.priceContainer}`}>
            <ToggleSwitch
              labels={["Aylık", "Yıllık"]}
              initialChecked={isAnnual}
              onToggle={handleToggle}
            />
            <h3 className={`${style.cardTitle}`}>
              {isAnnual ? "300₺ / ay" : "600₺ / ay"}
            </h3>
          </div>
          <div className={`${style.line2}`}></div>
          <p className={`${style.cardDescription} m-0`} style={{fontWeight:"bold"}}>
          TALENT14 STANDART
            </p>
          <FaPlus className={`${style.plusIcon}`}/>
          {premium.map((item, i) => (
            <p key={i} className={`${style.cardDescription} m-0`}>
              {item}
            </p>
          ))}
          <button className={`${style.btn}`}
            onClick={() => {
              const price = isAnnual ? "annually" : "monthly";
              window.location.href = `https://app.talent14.com/login?plan=premium&price=${price}`;
            }}
          >
            <span>Satın Al</span>
          </button>
        </div>

        <div className={style.card3}>
          <h3 className={`${style.cardTitle} m-0`}>Kurumsal</h3>
          <div className={`${style.line1}`}></div>
          <h3 className={`${style.cardTitle2} m-0`}>Teklif Alın</h3>
          <div className={`${style.line2}`}></div>
          <p className={`${style.cardDescription} m-0`} style={{fontWeight:"bold"}}>
          TALENT14 PREMIUM
            </p>
          <FaPlus className={`${style.plusIcon}`}/>
          {kurumsal.map((item, i) => (
            <p key={i} className={`${style.cardDescription} m-0`}>
              {item}
            </p>
          ))}
          <button className={`${style.btn}`} onClick={() => (window.location.href = "tel:+903129800020")}>
            <span>Teklif Al</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Section1;
