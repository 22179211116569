import React from "react";
import style from "./Student.module.css";
import studentCardIcon1 from "../../../../assets/studentCardIcon1.png";
import studentCardIcon2 from "../../../../assets/studentCardIcon2.png";
import studentCardIcon3 from "../../../../assets/studentCardIcon3.png";

const Student = () => {
  return (
    <div className={`${style.Student} m-0`}>
      <div className={`${style.wrapper} `}>
        <div className={`${style.top} `}>
          <span>Öğrenci</span>
        </div>
        <div className={`${style.title} `}>
          <span className={`${style.title1} `}>Bilişim Eğitimlerinin</span>{" "}
          <span className={`${style.title2} `}>En Eğlencelisi!</span>
        </div>
      <div className={style.cardContainer}>
        <div className={style.card1}>
          <div className={style.cardImage}>
          <img src={studentCardIcon1} alt="logo1"/>
          </div>
          <h3 className={`${style.cardTitle} m-0`}>Dersler</h3>
          <p className={`${style.cardDescription}`}>
            Uygulamaya dayalı 1000'den fazla video, 4 farklı yazılım dili ile
            Dijital Becerileri geliştirmeye odaklı, aynı zamanda hem video
            izlerken soru yanıtlayacağınız, örnek yapacağınız birçok etkileşime
            sahip aktiviteye dayalı eğitim platformudur.
          </p>
        </div>
        <div className={`${style.card2}`}>
          <div className={style.cardImage}>
          <img src={studentCardIcon2} alt="logo2"/>
          </div>
          <h3 className={`${style.cardTitle} m-0`}>İçerik</h3>
          <p className={`${style.cardDescription} m-0`}>
            Etkileşimli videolar, kodlama egzersizleri, soru cevaplar ve
            denemeler ve problem çözme araçlarıyla öğrenmeyi teşvik eden oyunlar
            içerir.
          </p>
        </div>
        <div className={style.card3}>
          <div className={style.cardImage}>
          <img src={studentCardIcon3} alt="logo3"/>
          </div>
          <h3 className={`${style.cardTitle} m-0`}>Alıştırma Ortamı</h3>
          <p className={`${style.cardDescription} m-0`}>
            Tüm uygulama platformlarında edindiğiniz bilgi ve
            tecrübeleri sıkça alıştırma yaparak bilgi kalıcılığını arttıran,
            çeşitli sınavlar, testler gibi uygulamaları içerisinde barındıran
            ölçme ve değerlendirme aşamalarını yapabileceğiniz uygulamalı
            alıştırma platformudur.
          </p>
        </div>
      </div>
      </div>

      <div className={`${style.moreDetail}`}>Daha fazla</div>
    </div>
  );
};

export default Student;
