import React from 'react';
import Section1 from './pricing/components/section1/Section1';
import Section2 from './pricing/components/section2/Section2';

const Pricing = () => {
  return (
    <div style={{overflow:"hidden"}}>
      <Section1/>
      <Section2/>
  </div>
  );
};

export default Pricing;
