import React from "react";
import style from "./Section2.module.css";
import { section2cards } from "./section2Cards";
import { Content } from "./Section2Card";

const Section2 = () => {
  return (
    <div className={`${style.section2} m-0 my-5`}>
      <div className={`${style.section2TitleContainer}`}>
        <p className={`${style.title}`}>
          <span className={`${style.wrapper}`}>21.Yüzyıla Hazırlayan</span>
          <span className={`${style.span}`}>Yetenekler</span>
        </p>
      </div>
      <div className={`${style.contentContainer} row`}>
      <div className={`${style.content1} col-6 col-md-4`}>
        <Content img={section2cards[0].image} title={section2cards[0].title} desc={section2cards[0].description}/>
      </div>
      <div className={`${style.content2} col-6 col-md-4`}>
        <Content img={section2cards[1].image} title={section2cards[1].title} desc={section2cards[1].description}/>
      </div>
      <div className={`${style.content3} col-6 col-md-4`}>
        <Content img={section2cards[2].image} title={section2cards[2].title} desc={section2cards[2].description}/>
      </div>
      <div className={`${style.content4} col-6 col-md-4`}>
        <Content img={section2cards[3].image} title={section2cards[3].title} desc={section2cards[3].description}/>
      </div>
      <div className={`${style.content5} col-6 col-md-4`}>
        <Content img={section2cards[4].image} title={section2cards[4].title} desc={section2cards[4].description}/>
      </div>
      <div className={`${style.content6} col-6 col-md-4`}>
        <Content img={section2cards[5].image} title={section2cards[5].title} desc={section2cards[5].description}/>
      </div>
      </div>
     
    </div>
  );
};

export default Section2;
