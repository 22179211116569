import React, { useState } from 'react';
import style from "./Section7.module.css";
import section7plusicon from "../../../../assets/section7plusicon.svg";
import section7multiplyicon from "../../../../assets/section7multiplyicon.svg";
import { motion } from 'framer-motion';

// Öğe verilerini bir dizi olarak tanımlıyoruz
const items = [
  { id: "01", title: "Kodlama Nedir Ve Neden Önemlidir?", content: "Kodlamayı en basit şekilde ‘Yeni Çağın Alfabesi’ olarak tanımlayabiliriz. Dünya artık her anını kodlamadan güç alarak döndürmektedir. Geleceği tamamen dijital olan dünyamızda kodlama öğrenmek, gençlerimizin yarının dünyasına uyum sağlamaları için çok değerli bir araçtır." },
  { id: "02", title: "Talent14 nedir?", content: "TALENT14 günümüz dijital dünyasının ihtiyaçlarına gençlerimizi hazırlamak amacıyla kurulmuş bir Bilişim School projesidir." },
  { id: "03", title: "Talent14'i neden seçmeliyim?", content: "TALENT14, öğrencinin eğlenirken öğrenmesini hedef almıştır. Bu sebepten ötürü içeriği oyunlar, eğlenceli alıştırmalar, interaktif eğitim videoları, kodlama ile müzik ve kodlama ile resim projeleri ile zenginleştirilmiştir. Codelab bünyesinde bulunan 1000den fazla eğitim videosu, 100 ün üzerinde alıştırma, 4 farklı yazılım dili ve interaktif oyunlarla zengin bir içeriğe sahiptir. Uygulamamız öğrenme patikaları çerçevesinde hazırlanmış olup çocuğunuzun sıkılmadan sürekli eğlenerek öğrenmesine sağlar." },
  { id: "04", title: "Talent14 hangi yaş gruplarına hitap ediyor?", content: "TALENT14 her yaş grubuna hitap etmektedir." },
  { id: "05", title: "Uygulamayı kullanırken canlı destek alabilecek miyim?", content: "Canlı destek birimimiz hem uygulama içerisinde hem de destek telefon hattımızda sorularınızı cevaplamak ve sizlere yardımcı olmak için günboyu sizlerin yanındadır." },
  { id: "06", title: "İçerikleri başarıyla tamamladıktan sonra sertifika alabilecek miyim?", content: "TALENT14 kolaydan zora sürekli gelişim odaklı bir platformdur. TALENT14’in içerisinde belirlenmiş olan aşamaları başarı ile geçen her öğrenci online sertifika ile ödüllendirilir." },
  { id: "07", title: "Çocuğumun gelişimini nasıl takip edebilirim?", content: "TALENT14 velilerin sürece dahil olmasına önem verir. Akıllı veli asistanımız ile çocuğunuzun gelişiminden düzenli olarak haberdar olabilirsiniz." },
  { id: "08", title: "Hangi ödeme planı bana uygun?", content: "TALENT14 ihtiyacınıza göre 3 farklı plan sunar. Bireysel olarak TALENT14 dünyasına katılmak isterseniz Ücretsiz, Standart ve Premium seçeneklerimize göz atabilirsiniz. Kurumsal çözümler için kurumsal planı seçip size özel çözümlerimizi danışmanınızdan öğrenebilirsiniz." },
  { id: "09", title: "Talent14'den memnun kalmazsam para iadesi alabilir miyim?", content: "TALENT14’den memnun kalmamanız durumunda 7 gün içinde para iadesinden faydalanabilirsiniz." }
];

const Section7 = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleDiv = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className={`${style.section7} my-5`}>
      <div className={`${style.wrapper} `}>
    <div className={`${style.leftBox} `}>
      <p className={`${style.title} m-0`}>Sıkça Sorulan Sorular</p>
      <p className={`${style.subTitle} m-0 `}>Daha fazla bilgi için bizimle iletişime geçin</p>
    </div>
    <div className={`${style.rightBox} `}>
      {items.map((item, index) => (
        <div className={`${style.questionWrapper} `} key={item.id}>
          <div 
          className={`${style.questionContainer} `}
            onClick={() => toggleDiv(index)}
          >
            <p className={`${style.questionNumber} m-0`}>{item.id}</p>
            <p className={`${style.questionTitle} m-0`} >{item.title}</p>
            {/* <div
            className={`${style.questionLogo} `} 
            >
              {openIndex === index ? <img src={section7multiplyicon} alt="" /> : <img src={section7plusicon} alt="" />}
            </div> */}
            <motion.div
                  className={`${style.questionLogo}`}
                  initial={{ rotate: 0, opacity: 0.5 }}
                  animate={{
                    rotate: openIndex === index ? 90 : 0,
                    opacity: openIndex === index ? 1 : 0.5,
                  }}
                  transition={{ duration: 0.3 }}
                >
                  {openIndex === index ? (
                    <img src={section7multiplyicon} alt="" />
                  ) : (
                    <img src={section7plusicon} alt="" />
                  )}
                </motion.div>
          </div>

          {openIndex === index && (
            <div className={`${style.questionDesc}`}>
              {item.content}
            </div>
          )}
        <div className={`${style.questionLine}`}></div>
        </div>
      ))}
    </div>
      </div>
      </div>
  );
};

export default Section7;
