import React from 'react'
import talent14Logo from "../assets/TALENT14_LOGO.png"
import style from "./HeaderLogo.module.css"
import { Link } from 'react-router-dom'

const HeaderLogo = ({logo}) => {
  return (
    <div className={`${style.Logo}`}>
    <Link to="/"><img className={`${style.LogoImg}`} src={logo} alt="" /></Link>
  </div>

  )
}

export default HeaderLogo