import React from "react";
import style from "./ToggleSwitch.module.css";

const ToggleSwitch = ({
  labels = ["Option 1", "Option 2"], // Varsayılan etiketler
  initialChecked = false,           // Varsayılan başlangıç durumu
  labelColor="#fff",
  onToggle = () => {},              // default callback
}) => {
  const [isChecked, setIsChecked] = React.useState(initialChecked);

  const handleSwitchChange = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    onToggle(newChecked);
  };
const labelStyle = {
    color:"#bdbdbd",
    fontFamily:"nunito",
}
const activeLabel = {
    color:labelColor,
    fontFamily:"nunito",
    fontWeight:"bold",
}
  return (
    <div className={`${style.switchContainer}`}>
      <span
        className={`${style.priceLabel} ${
          !isChecked ? style.activeLabel : ""
        }`}
        style={!isChecked ? activeLabel : labelStyle}
      >
        {labels[0]}
      </span>
      <label className={`${style.switch}`}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleSwitchChange}
        />
        <span className={`${style.slider} ${style.round}`}></span>
      </label>
        <span
        className={`${style.priceLabel} ${
          isChecked ? style.activeLabel : ""
        }`}
        style={isChecked ? activeLabel : labelStyle}
      >
        {labels[1]}
      </span>
    </div>
  );
};

export default ToggleSwitch;
