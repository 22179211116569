import React, { useState } from "react";
import style from "./Section2.module.css";
import ToggleSwitch from "../toggleSwitch/ToggleSwitch";
import { FaBan } from "react-icons/fa6";
import { plans } from "../../../../constants/plans";
import { CheckIcon, CrossIcon } from "../../../../assets/icons";

const Section2 = () => {
  const [isAnnual, setIsAnnual] = useState(true);
  const handleToggle = (checked) => {
    setIsAnnual(!isAnnual);
  };
  return (
    <div className={`${style.section2} m-0`}>
      <div className={`${style.wrapper}`}>
        <p className={`${style.title} m-0`}>Paketleri Karşılaştırın</p>
        <ToggleSwitch
          labels={["Aylık", "Yıllık"]}
          initialChecked={isAnnual}
          labelColor={"#000"}
          onToggle={handleToggle}
        />
        <div className={`${style.card}`}>
          <div className={`${style.titleContainer}`}>
            <div className={`${style.properties}`}>
              <h3 className={`${style.title} m-0`}>Özellikler</h3>
            </div>
            <div className={`${style.platformPlans}`}>
              <div className={`${style.free}`}>
                <h3 className={`${style.title} m-0`}>Ücretsiz</h3>
                <p className={`${style.price} m-0`}>0₺ / ay</p>
              </div>
              <div className={`${style.standart}`}>
                <h3 className={`${style.title} m-0`}>Standart</h3>
                <p className={`${style.price} m-0`}>
                  {isAnnual ? "200₺ / ay" : "400₺ / ay"}
                </p>
              </div>
              <div className={`${style.premium}`}>
                <h3 className={`${style.title} m-0`}>Premium</h3>
                <p className={`${style.price} m-0`}>
                  {isAnnual ? "300₺ / ay" : "600₺ / ay"}
                </p>
              </div>
            </div>
          </div>
          <div className={`${style.line}`}></div>
          {plans.map((plan, index) => (
            <React.Fragment key={index}>
              <div
                className={`${style.mainRow} ${
                  index % 2 === 0 ? style.oddRow : style.evenRow
                }`}
              >
                <div
                  className={`${style.propertiesColumn} ${
                    plan.type === "main"
                      ? style.propertiesTitle1
                      : style.propertiesTitle2
                  }`}
                >
                  {plan.title}
                </div>
                <div className={`${style.platformPlansColumn}`}>
                  <div className={`${style.freeColumn}`}>
                    {plan.type === "main" ? (
                      ""
                    ) : plan.free === "restricted" ? (
                      <FaBan
                        className={style.icon}
                        style={{ color: "#BEBEBE" }}
                      />
                    ) : plan.free ? (
                      <CheckIcon className={style.icon} />
                    ) : (
                      <CrossIcon className={style.icon} />
                    )}
                  </div>
                  <div className={`${style.standartColumn}`}>
                    {plan.type === "main" ? (
                      ""
                    ) : plan.standard === "restricted" ? (
                      <FaBan
                        className={style.icon}
                        style={{ color: "#BEBEBE" }}
                      />
                    ) : plan.standard ? (
                      <CheckIcon className={style.icon} />
                    ) : (
                      <CrossIcon className={style.icon} />
                    )}
                  </div>
                  <div className={`${style.premiumColumn}`}>
                    {plan.type === "main" ? (
                      ""
                    ) : plan.premium ? (
                      <CheckIcon className={style.icon} />
                    ) : (
                      <CrossIcon className={style.icon} />
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
          <p className={`${style.dipNot} m-0`}>
            <span className={`${style.asterisk}`}>*</span>Videoların tamamı
            etkileşimli olarak hazırlanmıştır.
          </p>
          <div className={`${style.btnContainer}`}>
            <button
              className={`${style.btn} ${style.btn1}`}
              onClick={() =>
                (window.location.href = "https://app.talent14.com/login")
              }
            >
              <span>Satın Al</span>
            </button>
            <button
              className={`${style.btn} ${style.btn2}`}
              onClick={() => {
                const price = isAnnual ? "annually" : "monthly";
                window.location.href = `https://app.talent14.com/login?plan=standart&price=${price}`;
              }}
            >
              <span>Satın Al</span>
            </button>
            <button
              className={`${style.btn} ${style.btn3}`}
              onClick={() => {
                const price = isAnnual ? "annually" : "monthly";
                window.location.href = `https://app.talent14.com/login?plan=premium&price=${price}`;
              }}
            >
              <span>Satın Al</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
