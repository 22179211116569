import React from 'react';
import Section1 from './platform/components/section1/Section1';
import Student from './platform/components/student/Student';
import Teacher from './platform/components/teacher/Teacher';
import Kurumsal from './platform/components/kurumsal/Kurumsal';

const Platform = () => {
  return (
    <div style={{overflow:"hidden"}}>
      <Section1/>
      <Student/>
      <Teacher/>
      <Kurumsal/>
  </div>
  );
};

export default Platform;
