import React from "react";
import style from "./Section6.module.css";
import partnerPino from "../../../../assets/partnerPino.png";
import partnerMorMaker from "../../../../assets/partnerMorMaker.png";
import partnerRKA from "../../../../assets/partnerRKA.png";
import partnerBuyukDegisim from "../../../../assets/partnerBuyukDegisim.png";

const Section6 = () => {
  return (
    <div className={`${style.section6} my-5`}>
      <div className={`${style.wrapper} `}>
        <p className={`${style.title} m-0`}>İş Birliklerimiz</p>
        <p className={`${style.description} m-0`}>
          Sizinle birlikte başarılı projeler yapmak ve büyümek için buradayız.
          İş ortaklarımızla güç birliği yaparak, yenilikçi çözümler üretiyor ve
          karşılıklı başarıyı hedefliyoruz.
        </p>
        <div className={`${style.partners} `}>
          <div className={`${style.partner1} `}>
            <img src={partnerPino} alt="partnerPino" />
          </div>
          <div className={`${style.partner2} `}>
            <img src={partnerMorMaker} alt="partnerMorMaker" />
          </div>
          <div className={`${style.partner3} `}>
            <img src={partnerRKA} alt="partnerRKA" />
          </div>
          <div className={`${style.partner4} `}>
            <img src={partnerBuyukDegisim} alt="partnerBuyukDegisim" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6;
