import React from 'react';
import Section1 from './home/components/section1/Section1';
import Section2 from './home/components/section2/Section2';
import Section3 from './home/components/section3/Section3';
import Section4 from './home/components/section4/Section4';
import Section5 from './home/components/section5/Section5';
import Section6 from './home/components/section6/Section6';
import Section7 from './home/components/section7/Section7';

const Home = () => {
  return (
    <div style={{overflow:"hidden"}}>
      <Section1/>
      <Section2/>
      <Section3/>
      <Section4/>
      <Section5/>
      <Section6/>
      <Section7/>
    </div>
  );
};

export default Home;
