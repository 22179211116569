import React from "react";
import style from "./Section4.module.css";
import logo1 from "../../../../assets/section4Application.png";
import logo2 from "../../../../assets/section4curriculum.png";
import logo3 from "../../../../assets/section4Output.png";
import logo4 from "../../../../assets/section4Trophy.png";
import background from "../../../../assets/section4Background.png";
import star from "../../../../assets/section4Star.svg";

const Section4 = () => {
  return (
    <div className={`${style.section4} my-5`}>
      <div className={`${style.wrapper} `}>
        <div className={`${style.properties} `}><span>Özellikler</span></div>
        <div className={`${style.title} `}><span>Bilişim Eğitimlerinin En Eğlencelisi!</span></div>
        <div className={`${style.propertiesTop} `}>
          <div className={`${style.propertiesCard} ${style.propertiesCard1} `}>
          <div className={`${style.content}`}>
            <div className={`${style.imgWrapper}`}>
              <img src={logo1} alt="logo1" />
            </div>
            <div className={`${style.detail}`}>
              <p className={`${style.title} m-0`}>Her adımda uygulama</p>
              <p className={`${style.desc} m-0`}>Özenle hazırlanmış öğrenme patikaları ile tüm eğitimleri uygulayarak öğrenin.</p>
            </div>
          </div>
          </div>
          <div className={`${style.propertiesCard} ${style.propertiesCard2} `}>
          <div className={`${style.content}`}>
            <div className={`${style.imgWrapper}`}>
              <img src={logo2} alt="logo2" />
            </div>
            <div className={`${style.detail}`}>
              <p className={`${style.title} m-0`}>Akıcı Müfredat</p>
              <p className={`${style.desc} m-0`}>Bilişimin ustaları tarafından özenle hazırlanmış güncel eğitim müfredatı ile yola çıkın.</p>
            </div>
          </div>
          </div>
        </div>
        <div className={`${style.propertiesBottom} `}>
          <div className={`${style.propertiesCard} ${style.propertiesCard3} `}>  <div className={`${style.content}`}>
            <div className={`${style.imgWrapper}`}>
              <img src={logo3} alt="logo3" />
            </div>
            <div className={`${style.detail}`}>
              <p className={`${style.title} m-0`}>Gerçek Çıktılar</p>
              <p className={`${style.desc} m-0`}>Eğitim ve oyunlar esnasında gerçek hata mesajlarını ve çıktılarını kolayca görün düzenleyin.</p>
            </div>
          </div></div>
          <div className={`${style.propertiesCard} ${style.propertiesCard4} `}>  <div className={`${style.content}`}>
            <div className={`${style.imgWrapper}`}>
              <img src={logo4} alt="logo4" />
            </div>
            <div className={`${style.detail}`}>
              <p className={`${style.title} m-0`}>Ödüller Ve Yarışlar</p>
              <p className={`${style.desc} m-0`}>Rozetler, Sertifikalar, Günlük ve Haftalık yarışmalar ile gelişiminize ve geleceğinize değer katın.</p>
            </div>
          </div></div>
        </div>
        <div className={`${style.background}`}>
          <img className={`${style.img1}`} src={background} alt="backgroundImage" />
        </div>
        <div className={`${style.background2}`}>
          <img className={`${style.img2}`} src={star} alt="starImage" />
        </div>
      </div>
    </div>
  );
};

export default Section4;
