import React from "react";
import style from "./Section1.module.css";
import Header from "../../../../components/Header";
import talent14Logo from "../../../../assets/TALENT14_LOGO.png"
import hamburgerIcon from "../../../../assets/hamburgerMenu.png"
import circle1 from "../../../../assets/circle1.png";
import circle2 from "../../../../assets/circle2.png";
import circle3 from "../../../../assets/circle2.png";
import Section1Card from "./Section1Card";

const Section1 = () => {
  return (
    <div className={`${style.section1} m-0`}>
        <div className={`${style.section1Header}`}>
      <Header logo={talent14Logo} hamburgerIcon={hamburgerIcon}/>
        </div>
      <img
        src={circle1}
        className={`${style.circle1}`}
        alt="Ön Plan Görüntüsü"
      />
      <img
        src={circle2}
        className={`${style.circle2}`}
        alt="Ön Plan Görüntüsü"
      />
      <img
        src={circle3}
        className={`${style.circle3}`}
        alt="Ön Plan Görüntüsü"
      />
      <div className={`${style.cardDiv}`}>
      <Section1Card/>
      </div>
    </div>
  );
};

export default Section1;
